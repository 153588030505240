import { gql } from "@apollo/client"

export const GET_GLOBAL_SITE_SETTINGS = gql`
  query GetGlobalSiteSettings {
    GlobalheaderItem(id: "global-content/header", resolve_links: "url") {
      content {
        show_alert_bar
        alert_bar_text
        alert_bar_navigation_menu
        primary_navigation_menu
        primary_navigation_menu_mobile
        secondary_navigation_menu
      }
    }
    GlobalfooterItem(id: "global-content/footer", resolve_links: "url") {
      content {
        disclosure_text
        footer_image {
          filename
        }
        navigation_menu
        social_links
      }
    }
  }
`

export const GET_BUNDLE_CARDS = gql`
  query GetBundleCards($uuids: String) {
    BundlepageItems(by_uuids: $uuids) {
      items {
        content {
          bundled_products_sanity
          product_name
          product_short_description
          thumbnail {
            filename
          }
          thumbnail_hover {
            filename
          }
        }
        full_slug
        uuid
      }
    }
  }
`

export const GET_BUNDLE_CARD_ITEM = gql`
  query GetBundleCardItem($slug: ID!) {
    BundlepageItem(id: $slug) {
      content {
        bundled_products_sanity
        product_name
        product_short_description
        thumbnail {
          filename
        }
        thumbnail_hover {
          filename
        }
      }
      full_slug
      uuid
    }
  }
`

export const GET_PRODUCT_CARD_ITEM = gql`
  query GetProductCardItem($slug: ID!) {
    ProductpageItem(id: $slug) {
      content {
        amazon_link {
          url
        }
        sanity_product
        product_name
        product_short_description
        thumbnail {
          filename
        }
        thumbnail_hover {
          filename
        }
      }
      full_slug
      uuid
    }
  }
`

export const GET_FAQ_CATEGORIES_PATHS = gql`
  query GetFAQCategoriesPaths {
    FaqpageItems(excluding_slugs: "faqs/") {
      items {
        content {
          title
        }
        full_slug
      }
    }
  }
`

export const GET_NEXT_JOURNAL_EXCERPT = gql`
  query GetNextJournalExcerpt($date: String!, $slug: String!) {
    JournalpostItems(
      first_published_at_gt: $date
      excluding_slugs: $slug
      sort_by: "first_published_at:desc"
      per_page: 1
    ) {
      items {
        content {
          component
          content
          featured_image {
            filename
          }
          title
        }
        full_slug
      }
    }
  }
`

export const GET_JOURNAL_FEATURED_POST = gql`
  query GetJournalFeaturedPost {
    JournalpostItems(sort_by: "first_published_at:desc", per_page: 1) {
      items {
        id
        content {
          component
          content
          featured_image {
            filename
          }
          title
        }
        full_slug
      }
    }
  }
`

export const GET_JOURNAL_POSTS_FOR_PAGE = gql`
  query GetJournalPostsForPage(
    $featuredPostId: String!
    $pageNumber: Int!
    $perPage: Int!
  ) {
    count: JournalpostItems {
      total
    }
    posts: JournalpostItems(
      sort_by: "first_published_at:desc"
      excluding_ids: $featuredPostId
      per_page: $perPage
      page: $pageNumber
    ) {
      items {
        id
        content {
          component
          content
          featured_image {
            filename
          }
          title
        }
        full_slug
      }
      total
    }
  }
`

export const GET_JOURNAL_PAGES_STATIC_PATHS = gql`
  query GetJournalPostItemsCount {
    JournalpostItems {
      total
    }
  }
`

export const GET_PATHS_FOR_BUNDLES = gql`
  query GetBundlePaths($perPage: Int!, $pageNumber: Int!) {
    BundlepageItems(page: $pageNumber, per_page: $perPage) {
      items {
        slug
      }
      total
    }
  }
`

export const GET_PATHS_FOR_FAQ = gql`
  query GetFAQPaths($perPage: Int!, $pageNumber: Int!) {
    FaqpageItems(
      excluding_slugs: "faqs/"
      page: $pageNumber
      per_page: $perPage
    ) {
      items {
        slug
      }
      total
    }
  }
`

export const GET_PATHS_FOR_JOURNAL = gql`
  query GetJournalPaths($perPage: Int!, $pageNumber: Int!) {
    JournalpostItems(page: $pageNumber, per_page: $perPage) {
      items {
        slug
      }
      total
    }
  }
`

export const GET_PATHS_FOR_PAGES = gql`
  query GetPagePaths($perPage: Int!, $pageNumber: Int!) {
    PageItems(page: $pageNumber, per_page: $perPage) {
      items {
        full_slug
      }
      total
    }
  }
`

export const GET_PATHS_FOR_PRODUCTS = gql`
  query GetProductPaths($perPage: Int!, $pageNumber: Int!) {
    ProductpageItems(page: $pageNumber, per_page: $perPage, excluding_slugs: "product/product-archive/*") {
      items {
        content {
          sanity_product
        }
        full_slug
      }
      total
    }
  }
`

export const GET_PATHS_FOR_SHOP = gql`
  query GetShopPagePaths($perPage: Int!, $pageNumber: Int!) {
    ShopcollectionItems(
      excluding_slugs: "shop/"
      page: $pageNumber
      per_page: $perPage
    ) {
      items {
        slug
      }
      total
    }
  }
`
