import { gql } from "@apollo/client"

export const FRAGMENT_ALL_PRODUCT_FIELDS = gql`
  fragment AllProductFields on Product {
    categoryId
    categoryName
    description
    isDelta8
    isThca
    name
    priceRetail
    priceOnSale
    priceSubscription
    priceWholesale
    sku
    stock
    thumbnailUrl
  }
`

export const GET_ALL_PUBLIC_SKUS_FROM_API = gql`
  query GetAllPublicSkusFromApi {
    skusForStoryblokPlugin {
      sku
    }
  }
`

export const GET_FULL_PRODUCT_LIBRARY_FROM_API = gql`
  ${FRAGMENT_ALL_PRODUCT_FIELDS}
  query GetFullProductLibraryFromApi {
    productLibrary {
      ...AllProductFields
    }
  }
`

export const GET_BUNDLE = gql`
  ${FRAGMENT_ALL_PRODUCT_FIELDS}
  query GetBundle($skus: [ID!]!) {
    bundle(skus: $skus) {
      products {
        ...AllProductFields
      }
      priceRetail
      priceOnSale
    }
  }
`

export const GET_BUNDLE_PRICES = gql`
  query GetBundlePrices($skus: [ID!]!) {
    bundle(skus: $skus) {
      priceOnSale
      priceRetail
    }
  }
`

export const GET_BUNDLE_PRICE_STOCK = gql`
  ${FRAGMENT_ALL_PRODUCT_FIELDS}
  query GetBundlePriceStock($skus: [ID!]!) {
    bundle(skus: $skus) {
      products {
        ...AllProductFields
      }
      priceRetail
      priceOnSale
    }
  }
`

export const GET_PRODUCT = gql`
  ${FRAGMENT_ALL_PRODUCT_FIELDS}
  query GetProduct($sku: ID!) {
    product(sku: $sku) {
      ...AllProductFields
    }
  }
`

export const GET_PRODUCT_PRICES = gql`
  query GetProductPrices($sku: ID!) {
    product(sku: $sku) {
      priceOnSale
      priceRetail
      priceSubscription
    }
  }
`

export const GET_PRODUCT_PRICE_STOCK = gql`
  query GetProductPriceStock($sku: ID!) {
    product(sku: $sku) {
      priceRetail
      priceOnSale
      priceSubscription
      priceWholesale
      stock
    }
  }
`

export const GET_PRODUCT_CARD_BY_SKU = gql`
  ${FRAGMENT_ALL_PRODUCT_FIELDS}
  query GetProductCard($sku: ID!) {
    product(sku: $sku) {
      ...AllProductFields
    }
  }
`

export default {}
